html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}


@font-face {
  font-family: "conthraxsb";
  src: local("conthraxsb"),
    url(../assets/fonts/conthrax-sb.ttf) format("truetype");
}

@font-face {
  font-family: "conthraxsb-semibold";
  src: local("coder"),
    url(../assets/fonts/conthrax-semibold.ttf) format("truetype");
}
/* 
@font-face {
  font-family: "futura";
  src: local("futura"),
    url(../assets/fonts/FuturaTS-ExtraLight.otf) format("opentype");
} */

@font-face {
  font-family: "futura";
  src: local("futura"),
    url(../assets/fonts/FuturaTSNewExtraLight.ttf) format("truetype");
}

@font-face {
  font-family: "Argentum";
  src: local("Argentum"),
    url(../assets/fonts/ArgentumSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Arial";
  src: local("Arial"),
    url(../assets/fonts/Arial-Black.ttf) format("truetype");
}

@font-face {
  font-family: "Bebas";
  src: local("Bebas"),
    url(../assets/fonts/bebas-neue.regular.ttf) format("truetype");
}

@font-face {
  font-family: "Archivo";
  src: local("Archivo"),
    url(../assets/fonts/ArchivoBlack-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "BebasLight";
  src: local("BebasLight"),
    url(../assets/fonts/BebasNeue\ Light.otf) format("opentype");
}

@font-face {
  font-family: "BebasBook";
  src: local("BebasBook"),
    url(../assets/fonts/BebasNeue\ Book.otf) format("opentype");
}

@font-face {
  font-family: "JetLight";
  src: local("JetLight"),
    url(../assets/fonts/JetBrainsMono-Light.ttf) format("truetype");
}

@font-face {
  font-family: "JetExtraLight";
  src: local("JetExtraLight"),
    url(../assets/fonts/JetBrainsMono-ExtraLight.ttf) format("truetype");
}

@font-face {
  font-family: "JetRegular";
  src: local("JetRegular"),
    url(../assets/fonts/JetBrainsMono-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "JetMedium";
  src: local("JetMedium"),
    url(../assets/fonts/JetBrainsMono-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "JetBold";
  src: local("JetBold"),
    url(../assets/fonts/JetBrainsMono-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "JetSemiBold";
  src: local("JetSemiBold"),
    url(../assets/fonts/JetBrainsMono-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "JetExBold";
  src: local("JetExBold"),
    url(../assets/fonts/JetBrainsMono-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "JetThin";
  src: local("JetThin"),
    url(../assets/fonts/JetBrainsMono-Thin.ttf) format("truetype");
}

/* @import url('https://fonts.googleapis.com/css2?family=Assistant:wght@500&family=Cabin:wght@500;700&family=Inconsolata:wght@200;400;500;600;700;800;900&family=Inter:wght@100;200;400;500;700;800&family=Martel+Sans:wght@900&family=Nanum+Pen+Script&family=Noto+Serif+Display:wght@100;700&family=Orbitron:wght@400;600;900&family=Outfit:wght@100&family=Quicksand:wght@400;500;700&family=Sarabun:wght@300&family=Space+Mono:wght@400;700&family=Work+Sans:wght@300&display=swap');body {
  margin: 0;
  font-family: "coder", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "futura", "Inter", "Nanum Pen Script", "Outfit",
    "Droid Sans", "Helvetica Neue", "Cabin", "Quicksand", "Sarabun", "Work Sans", "Martel Sans", "Orbitron", "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

html {
  background-color: "#4C4C4C";
}

div {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

/* .App {
  width: 50%;
  margin: 10rem auto;
  height: 570px;
} */

/* .slide img {
  width: 100%;
  margin: 0 auto;
}

.slide {
  transform: scale(0.9);
  transition: transform 300ms;
  opacity: 1;
  padding: 1%;
}

.activeSlide {
  transform: scale(1.1);
  opacity: 1;

}

.arrow {
  background-color: #fff;
  position: absolute;
  cursor: pointer;
  z-index: 10;

}

.arrow svg {
  transition: color 300ms;
}

.arrow svg:hover {
  color: #68edff;
}

.next {
  right: 0%;
  top: 50%;
}

.prev {
  left: 0%;
  top: 50%;
} */

.social svg {
  -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
  filter: invert(100%);
}

@media screen and (max-width: 600px) {
  .slide img {
    width: 100%;
  }
  
}

/* .animate {
  transition: transform .35s ease-in-out;
}

.animate.grow {
  transform: scale(1.5);
} */

/* canvas{
  z-index:-1;
  position:fixed;
  top:0%;
  left:0%;
} */


/* .shapes {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -2;
} */

.plus {
  width: 40px;
  height: 8px;
  margin: 5px;
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translateY(200%);
  transition: top 500ms cubic-bezier(0.31, 0.07, 0.27, 0.99),
    left 500ms cubic-bezier(0.31, 0.07, 0.27, 0.99),
    transform 500ms cubic-bezier(0.31, 0.07, 0.27, 0.99),
    opacity 500ms ease-in-out;
}

.plus::after {
  content: "";
  position: absolute;
  background: inherit;
  width: 8px;
  height: 40px;
  border-radius: 4px;
  transform: translate(190%, -40%);
}

.plus.green {
  background: var(--green);
}

.plus.blue {
  background: var(--blue);
}

.plus.orange {
  background: var(--orange);
}

.plus.pink {
  background: var(--pink);
}

.plus.purple {
  background: var(--purple);
}

.circle {
  width: 30px;
  height: 30px;
  margin: 5px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -2;
  transform: translateY(200%);
  transition: top 500ms cubic-bezier(0.31, 0.07, 0.27, 0.99),
    left 500ms cubic-bezier(0.31, 0.07, 0.27, 0.99),
    transform 500ms cubic-bezier(0.31, 0.07, 0.27, 0.99),
    opacity 500ms ease-in-out;
}

.circle.green {
  background: var(--green);
}

.circle.blue {
  background: var(--blue);
}

.circle.orange {
  background: var(--orange);
}

.circle.pink {
  background: var(--pink);
}

.circle.purple {
  background: var(--purple);
}

.exploding,
.exploding:focus,
.exploding:hover {
    background-color: transparent;
    color: transparent;
    pointer-events: none;
    position: relative;
    will-change: transform;
}

.particle {
    position: absolute;
    top: 0;
    left: 0;
    background-color: darkblue;
}

.visuallyhidden {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}
.icon {
  fill: var(--color-primary);
  width: 100%;
}
.btn {
  background-color: var(--color-primary);
  border: none;
  border-radius: 0.125rem;
  color: white;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  padding: 1rem 2.5rem 1.125rem;
}
.btn:focus {
  outline-color: var(--color-focus);
  outline-offset: 2px;
  outline-style: solid;
  outline-width: 3px;
}
.btn:active {
  transform: translateY(1px);
}
.slider__controls {
  display: flex;
  justify-content: center;
  position: absolute;
  top: calc(100% + 1rem);
  width: 100%;
}
.slider__controls .btn {
  --size: 3rem;
  align-items: center;
  background-color: transparent;
  border: 3px solid transparent;
  border-radius: 100%;
  display: flex;
  height: var(--size);
  padding: 0;
  width: var(--size);
}
.slider__controls .btn:focus {
  border-color: var(--color-focus);
  outline: none;
}
.slider__controls .btn--previous > * {
  transform: rotate(180deg);
}
.slider {
  --slide-size: 50vmin;
  --slide-margin: 1em;
  height: 50vmin;
  margin: 0 auto 0px auto;
  position: relative;
  width: 50vmin;
}

@media only screen and (max-width: 40em) {
  .slider { 
  --slide-size: 70vmin;
  height: 70vmin;
  width: 70vmin;
  --slide-margin: .2em;
  }
    }

.slider__wrapper {
  display: flex;
  margin: 0 calc(var(--slide-margin) * -1);
  position: absolute;
  top: 5%;
  transition: transform var(--base-duration) cubic-bezier(0.25, 1, 0.35, 1);
}
.slide {
  align-items: center;
  color: white;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: var(--slide-size);
  justify-content: center;
  margin: 0 var(--slide-margin);
  opacity: 0.55;
  position: relative;
  text-align: center;
  transition: opacity calc(var(--base-duration) / 2) var(--base-ease), transform calc(var(--base-duration) / 2) var(--base-ease);
  width: var(--slide-size);
  z-index: 1;
}
.slide--previous:hover, .slide--next:hover {
  opacity: 0.5;
}
.slide--previous {
  cursor: pointer;
  transform: scale(.95);
}
.slide--previous:hover {
  transform: translateX(2%);
}
.slide--next {
  cursor: pointer;
  transform: scale(.95);


}
.slide--next:hover {
  transform: translateX(-2%);
}
.slide--current {
  --x: 0;
  --y: 0;
  --d: 50;
  opacity: 1;
  pointer-events: auto;
  user-select: auto;
  transform: scale(1.075);
  /* --slide-size: 45vmin; */

}
@media (hover: hover) {
  .slide--current:hover .slide__image-wrapper {
    transform: scale(1.01) translate(calc(var(--x) / var(--d) * 1px), calc(var(--y) / var(--d) * 1px));
    
 }
}
.slide__image-wrapper {
  background-color: var(--color-accent);
  border-radius: 1%;
  height: 90%;
  left: 5%;
  overflow: hidden;
  position: absolute;
  top: 0%;
  transition: transform calc(var(--base-duration) / 4) var(--base-ease);
  width: 95%;
  box-shadow: rgba(60, 64, 67, 1) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

}
.slide__image {
  --d: 20;
  height: 110%;
  left: -5%;
  object-fit: cover;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: -5%;
  transition: opacity var(--base-duration) var(--base-ease), transform var(--base-duration) var(--base-ease);
  user-select: none;
  width: 110%;
  
}

@media only screen and (max-width: 40em) {
  .slide__image { 
  left: 0%;
  top: 0%;
  }
    }

@media (hover: hover) {
  .slide--current .slide__image {
    transform: translate(calc(var(--x) / var(--d) * 1px), calc(var(--y) / var(--d) * 1px));
    /* height: 150%; */
 }
 .slide--current .slide__image-wrapper {
  transform: translate(calc(var(--x) / var(--d) * 1px), calc(var(--y) / var(--d) * 1px));
}
 
}
.slide__headline {
  font-size: 8vmin;
  font-weight: 600;
  position: relative;
}
.slide__content {
  --d: 60;
  opacity: 0;
  padding: 4vmin;
  position: relative;
  transition: transform var(--base-duration) var(--base-ease);
  visibility: hidden;
}
.slide--current .slide__content {
  animation: fade-in calc(var(--base-duration) / 2) var(--base-ease) forwards;
  visibility: visible;
}
@media (hover: hover) {
  .slide--current .slide__content {
    transform: translate(calc(var(--x) / var(--d) * -1px), calc(var(--y) / var(--d) * -1px));
 }
}
.slide__content > * + * {
  margin-top: 2rem;
}
@keyframes fade-in {
  from {
    opacity: 0;
 }
  to {
    opacity: 1;
 }
}

#clockdiv{
	font-family: "JetSemiBold";
	color: #fff;
	/* display: inline-block; */
	font-weight: 100;
	text-align: left;
	font-size: 2rem;
  display: flex;
  flex-direction: row;

}

#clockdiv > div{
	padding: 0rem;
	/* border-radius: 3px; */
	background: none;
	display: flex;
}

#clockdiv div > span{
	/* padding: 15px; */
	/* border-radius: 3px; */
	background: none;
	display: flex;
  letter-spacing: .10rem;
  
}

.smalltext{
	/* padding-top: 5px; */
	font-size: 1rem;
}


.thintext{
  /* padding-top: 5px; */
  font-family: "JetSemiBold";
  font-size: 1.3rem;
}

@media only screen and (max-width: 40em) {
  #clockdiv, #clockdiv div > span { 
 font-size: .8rem;
 /* background-color: pink; */
 letter-spacing: .0rem;
  }
  .smalltext {
    font-size: .5rem;
  }
  .thintext {
    font-size: .5rem;
  }
    }


  @media only screen and (min-width: 40.063em) and (max-width: 64em) { 
    #clockdiv, #clockdiv div > span { 
    font-size: 1.2rem;
    /* background-color: blue; */
    
    /* letter-spacing: .1rem; */
    /* line-height: 1; */
    }
    .smalltext {
      font-size: 1.1rem;
    }
    .thintext {
      font-size: 1.1rem;   
    }
      } 

  @media only screen and (min-width: 64.063em) and (max-width: 90em) { 
    #clockdiv, #clockdiv div > span { 
      font-size: 1.5rem;
      /* background-color: red; */
      
      /* letter-spacing: .1rem; */
      /* line-height: 1; */
       }
       .smalltext {
         font-size: 1.1rem;
       }
       .thintext {
         font-size: 1.1rem;
       }
  } 

  @media only screen and (min-width: 90.063em) and (max-width: 120em) { 
    #clockdiv, #clockdiv div > span { 
      font-size: 2.2rem;
      /* background-color: white; */

      
      /* letter-spacing: .1rem; */
      /* line-height: 1; */
       }
       #smalltext {
         font-size: 2.2rem;
       }
       .thintext {
         font-size: 1rem;
       }
  } /* min-width 1441px, xlarge screens */

  @media only screen and (min-width: 120.063em) { 
    #clockdiv, #clockdiv div > span { 
      font-size: 2.2rem;

      
      /* letter-spacing: .1rem; */
      /* line-height: 1; */
       }
       .smalltext {
         font-size: 2.2rem;
       }
       .thintext {
         font-size: 1rem;
       }
  } /* min-width 1921px, xlarge screens */
        
  a {
    color: white;
    transition: ease 0.5s;
    text-decoration: none;
 }
   a:hover {
    color: #F0E4FF;
    transition: ease 0.5s;
 }
  