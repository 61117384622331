:root {
  --yellow: #fccd35;
  --pink: #ff19e9;
  --green: #00cc00;
  --white: #ffffff;
  --light-grey: #b1b1b1;
  --dark-grey: #2b2b2b;
  --black: #000000;
  --dark: #000f14;
  --light: #ebebeb;
  --bg: #031b26;
  --hover: #052839;
  --blue: #1495cc;
  --orange: #f75c03;

}
